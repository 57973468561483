<script>
  import { ja as localeJa } from "date-fns/locale";
  import {
    Render,
    Subscribe,
    createRender,
    createTable,
  } from "svelte-headless-table";
  import { addColumnFilters, addSortBy } from "svelte-headless-table/plugins";

  import { deletePreUploadData } from "~/libs/stores";
  import { formatStringDate } from "~/libs/utils";
  import DeleteTrashIcon from "~/pages/Upload/DeleteTrashIcon.svelte";

  /**
   * 登録済みの出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  export let results;
  /** @type {Function} */
  export let deletedResultsReplace;

  (() => {
    deletePreUploadData.set(results);
  })();

  $: if (results) {
    deletePreUploadData.set(results);
  }

  const table = createTable(deletePreUploadData, {
    sort: addSortBy({ toggleOrder: ["asc", "desc"] }),
    filter: addColumnFilters(),
  });

  const columns = table.createColumns(
    (() => {
      const columns = [
        table.column({
          header: "",
          id: "trashIcon",
          accessor: (item) => item,
          cell: (dataCell) =>
            createRender(DeleteTrashIcon, {
              record: dataCell.value,
              deletedResultsReplace: deletedResultsReplace,
            }),
        }),
        table.column({
          header: "切離し日",
          id: "toReceiveOn",
          accessor: (item) => item.toReceiveOn,
        }),
        table.column({
          header: "登録回",
          id: "sequentialNumber",
          accessor: (item) => item.sequentialNumber.toLocaleString(),
        }),
        table.column({
          header: "荷物数",
          id: "numberOfPackages",
          accessor: (item) => item.numberOfPackages.toLocaleString(),
        }),
        table.column({
          header: "最終更新日時",
          id: "createdAt",
          accessor: (item) =>
            formatStringDate(item.createdAt, "yyyy/MM/dd(E)HH:mm", {
              locale: localeJa,
            }),
        }),
      ];
      return columns;
    })(),
  );

  const { headerRows, rows, tableAttrs, tableHeadAttrs, tableBodyAttrs } =
    table.createViewModel(columns);
</script>

<div class="shippingReceiptUnitTable">
  <div class="mdc-data-table">
    <div class="mdc-data-table__table-container">
      <table class="mdc-data-table__table" {...$tableAttrs}>
        <thead {...$tableHeadAttrs}>
          {#each $headerRows as headerRow (headerRow.id)}
            <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
              <tr class="mdc-data-table__header-row" {...rowAttrs}>
                {#each headerRow.cells as cell (cell.id)}
                  <Subscribe
                    attrs={cell.attrs()}
                    let:attrs
                    props={cell.props()}
                    let:props
                  >
                    <th
                      class="mdc-data-table__header-cell"
                      {...attrs}
                      style="cursor: pointer;"
                      on:click={props.sort.toggle}
                    >
                      <div class="th-item">
                        <Render of={cell.render()} />
                        {#if props.sort.order === "asc"}
                          <span class="material-icons">arrow_upward</span>
                        {:else if props.sort.order === "desc"}
                          <span class="material-icons">arrow_downward</span>
                        {/if}
                      </div>
                      {#if props.filter?.render}
                        <div class="filter-area">
                          <Render of={props.filter.render} />
                        </div>
                      {/if}
                    </th>
                  </Subscribe>
                {/each}
              </tr>
            </Subscribe>
          {/each}
        </thead>
        <tbody class="mdc-data-table__content" {...$tableBodyAttrs}>
          {#if $rows.length === 0}
            <tr class="mdc-data-table__row">
              <td class="mdc-data-table__cell no_data_note" colspan="4">
                該当するデータがありません。
              </td>
            </tr>
          {:else}
            {#each $rows as row (row.id)}
              <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
                <tr class="mdc-data-table__row" {...rowAttrs}>
                  {#each row.cells as cell (cell.id)}
                    <Subscribe attrs={cell.attrs()} let:attrs>
                      {#if cell.id === "toReceiveOn"}
                        <td class="mdc-data-table__cell" {...attrs}>
                          {formatStringDate(cell.render(), "yyyy/MM/dd(E)", {
                            locale: localeJa,
                          })}
                          {#if new Date().getTime() - new Date(cell.render()).getTime() > 2 * 24 * 60 * 60 * 1000}
                            <span class="cautionMark">2日以上前</span>
                          {/if}
                        </td>
                      {:else if cell.id === "sequentialNumber"}
                        <td class="mdc-data-table__cell number-cell" {...attrs}>
                          <Render of={cell.render()} />回目
                        </td>
                      {:else if cell.id === "numberOfPackages"}
                        <td class="mdc-data-table__cell number-cell" {...attrs}>
                          <Render of={cell.render()} />個
                        </td>
                      {:else}
                        <td class="mdc-data-table__cell" {...attrs}>
                          <Render of={cell.render()} />
                        </td>
                      {/if}
                    </Subscribe>
                  {/each}
                </tr>
              </Subscribe>
            {/each}
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</div>

<style lang="scss">
  .shippingReceiptUnitTable {
    :global(.mdc-data-table) {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      max-height: calc(100vh - 236px);
      overflow-x: auto;
      overflow-y: none;
    }

    :global(.mdc-data-table__table thead) {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .number-cell {
      text-align: right;
    }

    th {
      background-color: #eaf5ff;
      vertical-align: middle;
      font-size: small;

      .th-item {
        display: flex;
        position: relative;

        span {
          position: relative;
          margin-left: 3px;
          top: 3px;
          font-size: 18px;
          color: #5c5c5c;
        }
      }
    }

    td {
      vertical-align: middle;
      font-size: small;
      position: relative;
    }

    tr th:nth-child(1),
    tr td:nth-child(1) {
      width: 1px;
      padding-left: 4px;
      padding-right: 0;
    }

    .cautionMark {
      background-color: #bd362f;
      border-radius: 5px;
      color: #fff;
      display: inline-block;
      font-size: 85%;
      padding: 2px 5px;
      margin: 0 5px;
    }
  }
</style>
