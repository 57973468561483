<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { createEventDispatcher, getContext } from "svelte";
  import {
    Render,
    Subscribe,
    createRender,
    createTable,
  } from "svelte-headless-table";
  import { addColumnFilters, addSortBy } from "svelte-headless-table/plugins";

  import SelectFilter from "~/components/SelectFilter.svelte";
  import TextFilter from "~/components/TextFilter.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import {
    matchFilter,
    numberRangeFilter,
    textIncludeFilter,
  } from "~/libs/filters";
  import { activityResultData } from "~/libs/stores";
  import {
    getCenterNameString,
    getDaysFromLastWorkedAt,
    getWorkedAtString,
  } from "~/pages/Activity/activityUtils";
  import LastWorkedStatus from "~/pages/Activity/LastWorkedStatus.svelte";
  import NumberRangeFilter from "~/pages/Activity/NumberRangeFilter.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Array<import("~/libs/commonTypes").DriverActivity>} */
  export let results = [];

  /** 現在選択中のドライバー番号 */
  export let currentDriverIndex;

  /** @type {boolean} 確認状態を保存するボタンの活性非活性 */
  export let disabledUpdateButton = true;

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  export let centersMap;

  /** @type {string} 「表示名」のフィルターの値 */
  export let filterValueOfUserDisplayName;

  /** @type {Array<number, number>} 「最終勤務」のフィルターの値 */
  export let filterOfLastWorkedStatus;

  /** @type {string} 「所属会社」のフィルターの値 */
  export let filterValueOfCompanyName;

  /** @type {string} 「担当配送センター」のフィルターの値 */
  export let filterValueOfLocationId;

  (() => {
    activityResultData.set(results);

    results.forEach((result) => {
      const workTimes = result.workTimes;
      if (!workTimes) {
        return;
      }

      const lastTimeset = workTimes[workTimes.length - 1];
      if (lastTimeset.endAt) {
        // 業務終了日時が存在する場合は業務終了済みフラグを立てる
        result.isFinished = true;
      }
    });
  })();

  const dispatch = createEventDispatcher();

  const table = createTable(activityResultData, {
    sort: addSortBy({ toggleOrder: ["asc", "desc"] }),
    filter: addColumnFilters(),
  });

  const columns = table.createColumns(
    (() => {
      const columns = [
        table.column({
          header: "No.",
          id: "driverIndex",
          accessor: (item) => item.index,
        }),
        table.column({
          header: "表示名",
          id: "userDisplayName",
          accessor: (item) => item.userDisplayName ?? "",
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: filterValueOfUserDisplayName,
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }).on("filter", (event) => {
                  filterValueOfUserDisplayName = event.detail.value;
                }),
            },
          },
        }),
        table.column({
          header: "最終勤務",
          id: "lastWorkedStatus",
          accessor: (item) => getDaysFromLastWorkedAt(item.workTimes),
          plugins: {
            filter: {
              fn: numberRangeFilter,
              initialFilterValue: filterOfLastWorkedStatus,
              render: ({ filterValue }) =>
                createRender(NumberRangeFilter, { filterValue }).on(
                  "filter",
                  (event) => {
                    filterOfLastWorkedStatus = event.detail.value;
                  },
                ),
            },
          },
        }),
        table.column({
          header: "",
          id: "lastWorkedAt",
          accessor: (item) => getWorkedAtString(item.workTimes),
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "管理者確認",
          id: "managerConfirmation",
          accessor: (item) => item.checked,
        }),
        table.column({
          header: "所属会社",
          id: "companyName",
          accessor: (item) => item.companyName ?? "",
          plugins: {
            filter: {
              fn: matchFilter,
              initialFilterValue: filterValueOfCompanyName,
              render: ({ filterValue, preFilteredValues }) =>
                createRender(SelectFilter, {
                  filterValue,
                  preFilteredValues,
                  results,
                  columnId: "companyName",
                }).on("filter", (event) => {
                  filterValueOfCompanyName = event.detail.value;
                }),
            },
          },
        }),
        table.column({
          header: "担当配送センター",
          id: "locationId",
          accessor: (item) =>
            getCenterNameString(centersMap, item.results?.locationSourceIdList),
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: filterValueOfLocationId,
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }).on("filter", (event) => {
                  filterValueOfLocationId = event.detail.value;
                }),
            },
          },
        }),
        table.column({
          header: "未配達",
          id: "undelivered",
          accessor: (item) => item.deliveryRecordsCount.undelivered,
        }),
        table.column({
          header: "配達済",
          id: "delivered",
          accessor: (item) => item.deliveryRecordsCount.delivered,
        }),
        table.column({
          header: "配達不可",
          id: "undeliverable",
          accessor: (item) => item.deliveryRecordsCount.undeliverable,
        }),
      ];
      return columns;
    })(),
  );

  const { headerRows, rows, tableAttrs, tableHeadAttrs, tableBodyAttrs } =
    table.createViewModel(columns);

  $: resultsNum = $rows.length;
</script>

<div class="courierActiviryTable">
  <div class="resultHeader">
    <h1 class="resultNumber">全 {resultsNum.toLocaleString()}件</h1>
    <div class="confirmArea">
      <Button
        variant="unelevated"
        disabled={disabledUpdateButton}
        on:click={() => {
          dispatch("updateDriverActivityCheck");
        }}>確認状態を保存</Button
      >
    </div>
  </div>
  <div class="mdc-data-table">
    <div class="mdc-data-table__table-container">
      <table class="mdc-data-table__table" {...$tableAttrs}>
        <thead {...$tableHeadAttrs}>
          {#each $headerRows as headerRow (headerRow.id)}
            <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
              <tr class="mdc-data-table__header-row" {...rowAttrs}>
                {#each headerRow.cells as cell (cell.id)}
                  <Subscribe
                    attrs={cell.attrs()}
                    let:attrs
                    props={cell.props()}
                    let:props
                  >
                    <th
                      class="mdc-data-table__header-cell"
                      {...attrs}
                      style={cell.id === "statusIcon" ||
                      cell.id === "detailButton"
                        ? ""
                        : "cursor: pointer;"}
                      on:click={props.sort.toggle}
                    >
                      <div class="th-item">
                        <Render of={cell.render()} />
                        {#if props.sort.order === "asc"}
                          <span class="material-icons">arrow_upward</span>
                        {:else if props.sort.order === "desc"}
                          <span class="material-icons">arrow_downward</span>
                        {/if}
                      </div>
                      {#if props.filter?.render}
                        <div class="filter-area">
                          <Render of={props.filter.render} />
                        </div>
                      {/if}
                    </th>
                  </Subscribe>
                {/each}
              </tr>
            </Subscribe>
          {/each}
        </thead>
        <tbody class="mdc-data-table__content" {...$tableBodyAttrs}>
          {#if $rows.length === 0}
            <tr class="mdc-data-table__row">
              <td class="mdc-data-table__cell" colspan="9">
                該当するデータがありません。
              </td>
            </tr>
          {:else}
            {#each $rows as row (row.id)}
              <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
                <tr
                  class="mdc-data-table__row"
                  class:selectedRow={String(currentDriverIndex) ===
                    row.cells[0].render()}
                  {...rowAttrs}
                  on:click={() => {
                    currentDriverIndex = row.cells[0].render();
                  }}
                >
                  {#each row.cells as cell (cell.id)}
                    <Subscribe attrs={cell.attrs()} let:attrs>
                      <td
                        class="mdc-data-table__cell"
                        class:right={cell.id === "undelivered" ||
                          cell.id === "delivered" ||
                          cell.id === "undeliverable"}
                        class:center={cell.id === "managerConfirmation"}
                        {...attrs}
                      >
                        {#if cell.id === "managerConfirmation"}
                          {#if results[Number(row.cells[0].render()) - 1].companyId !== userContext.loginUser.companyId}
                            <span class="disabledConfirmation">－</span>
                          {:else}
                            <FormField>
                              <Checkbox
                                on:click$stopPropagation
                                on:change={() => {
                                  disabledUpdateButton = false;
                                }}
                                bind:checked={
                                  results[Number(row.cells[0].render()) - 1]
                                    .checked
                                }
                              />
                            </FormField>
                          {/if}
                        {:else if cell.id === "locationId"}
                          <!-- 内部で生成した文言しか埋め込まれないためエスケープ不要 -->
                          {@html cell.render()}
                        {:else if cell.id === "lastWorkedStatus"}
                          <LastWorkedStatus
                            numberOfDays={Number(cell.render())}
                            isFinished={results[
                              Number(row.cells[0].render()) - 1
                            ].isFinished}
                          />
                        {:else if cell.id === "lastWorkedAt"}
                          <div class="lastWorkedTimes">
                            <!-- 内部で生成した文言しか埋め込まれないためエスケープ不要 -->
                            {@html cell.render()}
                          </div>
                        {:else if cell.id === "undelivered" || cell.id === "delivered" || cell.id === "undeliverable"}
                          <Render of={`${cell.render().toLocaleString()}件`} />
                        {:else}
                          <Render of={cell.render()} />
                        {/if}
                      </td>
                    </Subscribe>
                  {/each}
                </tr>
              </Subscribe>
            {/each}
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</div>

<style lang="scss">
  .courierActiviryTable {
    :global(.mdc-data-table__table-container) {
      max-height: calc(100vh - 295px);
      min-height: calc(100vh - 295px);
      overflow: auto;
    }
    :global(.mdc-data-table__cell) {
      overflow: unset;
    }
    :global(.mdc-tooltip-wrapper--rich .tooltip-eventer) {
      box-sizing: border-box;
      padding: 15.5px 6px;
      height: 51px;
    }
    :global(
      .mdc-data-table__row
        .mdc-data-table__cell:first-of-type
        .mdc-tooltip-wrapper--rich
        .tooltip-eventer
    ) {
      padding: 10.5px 16px;
    }
    .resultHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
    .resultNumber {
      margin: 12px 0 5px 20px;
    }
    .confirmArea {
      margin: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 4px;
    }
    :global(.mdc-data-table) {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      max-height: calc(100vh - 236px);
      overflow-x: auto;
      overflow-y: none;
    }
    :global(.mdc-data-table__table thead) {
      position: sticky;
      top: 0;
      z-index: 2;
    }
    .mdc-data-table__row {
      cursor: pointer;

      &.selectedRow {
        background-color: aliceblue;
        font-weight: 900 !important;
      }

      .right {
        text-align: right;
      }

      .center {
        text-align: center;
      }

      .disabledConfirmation {
        margin-right: 4px;
      }

      .mdc-button {
        padding: 0;
        border-radius: 50%;
        height: 35px;
        min-width: 35px;
        background-color: #eaf5ff;
        border: none;
        cursor: pointer;
        display: flex;
      }

      .mdc-button:hover {
        background-color: #d6e9ff;
      }
    }
    .memo-cell {
      min-width: 420px;
      padding: 8px 16px;
      white-space: break-spaces;
      overflow-wrap: break-word;
    }
    .address-cell {
      min-width: 340px;
      padding: 8px 16px;
      white-space: break-spaces;
      overflow-wrap: break-word;
    }
    .updated-user-cell {
      min-width: 200px;
      padding: 8px 16px;
      white-space: break-spaces;
      overflow-wrap: break-word;
    }
    th {
      background-color: #eaf5ff;
      vertical-align: middle;
      font-size: small;

      .th-item {
        display: flex;
        position: relative;

        span {
          position: relative;
          margin-left: 3px;
          top: 3px;
          font-size: 18px;
          color: #5c5c5c;
        }
      }
    }
    td {
      vertical-align: middle;
      font-size: small;
    }
    .lastWorkedAt {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 2px;
    }
    .lastWorkedTimes {
      margin: 6px 0;
    }
  }

  @media screen and (max-width: 768px) {
    .courierActiviryTable {
      :global(.mdc-data-table) {
        height: fit-content;
        max-height: 436px;
        min-height: auto;
      }
      :global(.mdc-data-table__table-container) {
        max-height: 436px;
        min-height: auto;
      }
    }
  }
</style>
