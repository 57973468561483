<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    DepotTypes,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import loadingProgress from "~/libs/loadingProgress";
  import { activeContent } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import ConfirmedUpload from "~/pages/Upload/ConfirmedUpload.svelte";
  import Delete from "~/pages/Upload/Delete.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  const CONFIRMED_UPLOAD = userContext.hasEcAdminRole()
    ? "出荷確定登録"
    : "事前荷受け登録";
  const DELETE_INFO = "登録情報の削除";

  /** 選択中のメニュー @type {CONFIRMED_UPLOAD | DELETE_INFO} */
  export let active;

  /**
   * 登録済みの出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  export let shippingReceiptUnitList;

  /**
   * 配送センター一覧を格納する配列
   * @type {Array<import("~/libs/commonTypes").DepotLocation>}
   */
  let locationList = [];

  /**
   * 荷受拠点の配送センター一覧を格納する配列
   * @type {Array<import("~/libs/commonTypes").DepotLocation>}
   */
  let receiptLocationList = [];

  /** 選択されている切離し拠点のid @type {number} */
  let selectedReceiptLocationId = appContext.uploadedReceiptCenterId;

  // ページの初期化処理（非同期）
  loadingProgress.wrapAsync(async () => {
    try {
      // 配送センター一覧の取得
      locationList = await depotLocations.get();
      receiptLocationList = locationList
        .map((location) => {
          let filtered = location.centers.filter((center) => {
            return center.type & DepotTypes.EC;
          });
          return {
            prefecture: location.prefecture,
            centers: filtered,
          };
        })
        .filter((location) => location.centers.length > 0);
    } catch (error) {
      console.error(error);
      toast.error($_("errors.updateDefaultMessage.message"));
      activeContent.set("Home");
    }
  })();

  function changedSelectedReceiptLocationId(event) {
    selectedReceiptLocationId = event.detail.value;
  }

  function changedShippingReceiptUnitList(event) {
    shippingReceiptUnitList = event.detail.value;
  }

  function deletedShippingReceiptUnit() {
    dispatch("deletedShippingReceiptUnit");
  }
</script>

{#if active === CONFIRMED_UPLOAD}
  <ConfirmedUpload
    {locationList}
    {receiptLocationList}
    {shippingReceiptUnitList}
    {selectedReceiptLocationId}
    on:changedSelectedReceiptLocationId={changedSelectedReceiptLocationId}
    on:changedShippingReceiptUnitList={changedShippingReceiptUnitList}
  />
{:else if active === DELETE_INFO}
  <Delete
    {receiptLocationList}
    {shippingReceiptUnitList}
    {selectedReceiptLocationId}
    on:changedSelectedReceiptLocationId={changedSelectedReceiptLocationId}
    on:deletedShippingReceiptUnit={deletedShippingReceiptUnit}
  />
{/if}
